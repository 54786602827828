import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    private baseURL = '';
    public locationVendor: any[] = []
    constructor(private http: HttpClient, public env: EnvService) {
        this.baseURL = env.RESTURLPrefix + 'REST/';
    }
    loadLocations(): void {
        this.http.get<any>(`${this.baseURL}customer/locations/vendors`).subscribe(
            (response) => {
                this.locationVendor = response.data
            }
        );
    }
}
