import * as _moment from 'moment';
const moment = _moment;

export class AuthCustomer {
  id: string;
  name: string;
  urlName: string;
  projects: Project[];
  initials: string;
  onlyMobile: boolean;
  allDeactivated: boolean;

  constructor(data: CustomerPortalResponse) {
    this.id = data.customer_id;
    this.name = data.customer;
    this.urlName = urlize(data.customer);
    this.initials = data.user[0].firstname.split('')[0] + data.user[0].last_name.split('')[0];
    this.onlyMobile = data.user[0].other_status.mobile;
    this.allDeactivated = data.user[0].other_status.deactivated;
    const projectsWithPortals = data.project.filter(projectData => projectData.project_portal.length);

    this.projects = projectsWithPortals.map((projectData) => new Project(data, projectData));

    this.projects.sort((a, b) => {
      if (a.type !== b.type) {
        return projectTypes.findIndex(type => type === a.type) > projectTypes.findIndex(type => type === b.type) ? 1 : -1;
      }
      return 0;
    });
  }
}

export class Project {
  id: string;
  name: string;
  urlName: string;
  type: string;
  typeIndicator: string;
  role: string;
  ongoingOrders: number;
  completeOrders: number;
  activeUsers: number;
  portalNavs: PortalNavLink[];
  projectPageLink: string;
  routerLinkProject: { project: string };
  customerId: string;
  customerName: string;
  customerUrlName: string;
  location: LocationList[];
  point_of_contact: PointofContact[];

  constructor(customerData: CustomerPortalResponse, data: ProjectResponse) {
    this.id = data.project_id;
    this.name = data.project_name;
    this.urlName = urlize(data.project_name);
    this.routerLinkProject = { project: this.urlName };
    this.type = data.project_type;
    this.typeIndicator = '(' + data.project_type.split('')[0] + ')';
    this.role = data.role;
    this.ongoingOrders = data.ongoing_orders;
    this.completeOrders = data.complete_orders;
    this.activeUsers = data.active_user;
    this.point_of_contact = data['point_of_contact'].map(loc => new PointofContact(loc));
    this.location = !data['locations'].length ? [] : data['locations'].map(loc => new LocationList(loc));
    const filterNavs = data.project_portal.filter(portalData => {
      if ((this.role !== 'On-site') || (portalData['url'] !== 'marketplace')) { return portalData };
    });
    this.portalNavs = filterNavs.map((portalData) => new PortalNavLink(portalData, this.urlName));
    if (!this.portalNavs.map(nav => nav.link).includes('/app/settings')) {
      const settingsData = portalMapping[0];
      this.portalNavs.push(new PortalNavLink(settingsData, this.urlName));
    }

    this.projectPageLink = this.portalNavs[0].link.slice(5);
    this.customerId = customerData.customer_id;
    this.customerName = customerData.customer;
    this.customerUrlName = urlize(customerData.customer);
  }
  minDate() {
    if (this.type === 'Legacy') {
      return moment.utc('2017-01-01').format('YYYY-MM-DD');
    } else {
      return moment.utc('2019-06-01').format('YYYY-MM-DD');
    }
  }
  maxDate() {
    if (this.type === 'Legacy') {
      return moment.utc('2020-05-31').format('YYYY-MM-DD');
    } else {
      return moment.utc().format('YYYY-MM-DD');
    }
  }
}
export class LocationList {
  name: string;
  city: string;
  state: string;
  street: string;
  zip: string;
  address: string;
  location_id: string;
  material: Material[];
  operating_hours: OperatingHours[];
  constructor(data: LocationList) {
    this.name = data['name'];
    this.city = data['city'];
    this.state = data['state'];
    this.zip = data['zip'];
    this.street = data['street'];
    this.address = `${data['street']} ${data['city']}, ${data['state']} ${data['zip']} | ${data['name']}`,
    this.location_id = data['id']
    this.material = !data['materials'].length ? [] : data['materials'].map(ele => new Material(ele));
    this.operating_hours = !data['operating_hours'].length ? [] : data['operating_hours'].map(ele => new OperatingHours(ele));
  }
}

export class PointofContact {
  email: string;
  full_name: string;
  pointofContactid: string;
  phone: string;
  type: string;
  pocValue: string;
  filterValue: string
  constructor(data: PointofContact) {
    this.email = data['email'];
    this.full_name = data['full_name'];
    this.pointofContactid = data['id'];
    this.phone = transFormPhoneFormate(data['phone']);
    this.type = data['type'];
    this.pocValue = `${this.type} | ${this.full_name} | ${this.phone} | ${this.email}`;
    this.filterValue = `${this.type} | ${this.full_name} | ${data['phone']} | ${this.email}`;
  }
}

export class Material {
  delivery?: string
  material_id?: string
  material_name: string
  order_number?: string
  pick_up?: string
  sub_material_id: string
  sub_material_name: string
  unit: string
  unit_id: string
  quantity: number
  source: string
  pick_up_name?: string
  constructor(data: Material) {
    this.delivery = data['delivery'];
    this.material_id = data['material_id'];
    this.material_name = data['material_name'];
    this.order_number = data['order_number'];
    this.pick_up = data['pick_up'];
    this.sub_material_id = data['sub_material_id'];
    this.sub_material_name = data['sub_material_name'];
    this.unit = data['unit'];
    this.unit_id = data['unit_id'];
    this.quantity = data['quantity'];
    this.source = data['source'];
    this.pick_up_name=data['pick_up_name']
  }
}

export class OperatingHours {
  close_time: string
  day_name: string
  open_time: string
  constructor(data: OperatingHours) {
    this.close_time = data['close_time'];
    this.day_name = data['day_name'];
    this.open_time = data['open_time'];
  }
}

export class PortalNavLink {
  name: string;
  link: string;
  icon: string;
  routerLinkProject: {project: string};
  constructor(data: ProjectPortalResponse, projectUrlName: string) {    
    this.name = data.portal;
    this.link = '/app/' + data.url;
    this.icon = data.mat_icon;
    this.routerLinkProject = {project: projectUrlName};
  }
}

export interface CustomerPortalResponse {
  customer: string;
  customer_id: string;
  project: ProjectResponse[];
  user: UserInitials[];
}

interface UserInitials {
  firstname: string;
  last_name: string;
  other_status: OtherStatus;
}

interface OtherStatus {
  mobile?: boolean;
  deactivated?: boolean;
}

interface ProjectResponse {
  active_user: number;
  complete_orders: number;
  ongoing_orders: number;
  project_id: string;
  project_name: string;
  project_type: string;
  role: string;
  project_portal: ProjectPortalResponse[];
}

interface ProjectPortalResponse {
  portal: string;
  url: string;
  mat_icon: string;
}

const urlize = (name: string): string => {
  name = name.replace(/[^a-z0-9]/gi, '_');
  return name;
};

const projectTypes = ['Ongoing', 'Closed', 'Legacy'];

const portalMapping: ProjectPortalResponse[] = [
  { url: 'settings', portal: 'Settings', mat_icon: 'settings' },
  // Order menu added for the MO-804 - Orders Page - Display All Orders w/ Workflow Features
  // { url: 'order', portal: 'Order', mat_icon: 'order' },
];

const transFormPhoneFormate = (phone: string): string => {
  // Ex:
  // Expected input into method: 8908998676(string) 
  // Expected output from method: (890) 899-8676(Formated phone number)
  if (phone) {
    let len = phone.length;
    switch (len) {
      case 10:
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
      default:
        return phone;
    }
  }
  return phone
}

