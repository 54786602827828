import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EnvService } from '@services/env.service';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';
  route: any = window.location.pathname;

  constructor(
    private activePage: ActivatedRoute,
    public env: EnvService,
    public router: Router,
    public titleService: Title,
    private gtmService: GoogleTagManagerService
  ) {
    this.dynamicallyAddScript(
      'assets/scripts/analytics.js?id=' + this.env.googleAnalyticsAPI
    );
    this.dynamicallyAddScript(
      'https://maps.googleapis.com/maps/api/js?key=' +
        this.env.googleAPIKey +
        '&libraries=places,geometry,drawing'
    );
    this.dynamicallyAddScript("assets/scripts/gtag.js");
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

  }
  dynamicallyAddScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit() {
    this.changeTitle();
  }
  changeTitle() {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationEnd:
          this.titleService.setTitle(
            `Aggdirect-Customer-${this.activePage?.firstChild?.firstChild?.firstChild?.snapshot.data.title}`
          );
          break;

        default:
          break;
      }
    });
  }
}
